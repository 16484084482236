import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import OrderContainer from '../../common/OrderContainer';
import Step1 from '../../../components/Step1v1';
import { trackEvent, setProperty } from '../../../services/analytics';
import featureSwitch from '../../../services/featureSwitch';
import '../../../components/popup.css';

class ClothesContainer extends Component {
  constructor() {
    super();
    this.goNext = this.goNext.bind(this);
    this.skipStep2 = this.skipStep2.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);

    if (featureSwitch('step1_skipAndAdd1DecoCloth')) {
      if (!this.props.clothes || this.props.clothes.length === 0) {
        this.props.dispatch({ type: 'ORDER.ADD_CLOTH', cloth: { slug: 'deco', name: 'Rideau' } });
      }
      navigate(this.props.estimationVariantRoute);
    }
  }

  addCloth(cloth) {
    trackEvent('ajouter-panier', cloth.slug);
    this.props.dispatch({ type: 'ORDER.ADD_CLOTH', cloth });
  }

  removeCloth(slug) {
    trackEvent('enlever-panier-etape1', slug);
    this.props.dispatch({ type: 'ORDER.REMOVE_CLOTH_BY_SLUG', slug });
  }

  isOrderValid() {
    return this.props.clothes && this.props.clothes.length > 0;
  }

  skipStep2() {
    setProperty('commande-parcours', 'rapide');
    trackEvent('click-step1', 'rapide');
    navigate(this.props.fastVariantRoute);
  }

  goNext() {
    if (this.isOrderValid()) {
      trackEvent('click-step1', 'estimation');
      setProperty('commande-parcours', 'estimation');
      navigate(this.props.estimationVariantRoute);
    } else {
      this.skipStep2();
    }
  }

  render() {
    return (
      <OrderContainer>
        <Step1
          clothes={this.props.clothes}
          addCloth={cloth => this.addCloth(cloth)}
          removeCloth={slug => this.removeCloth(slug)}
          isNotValid={!this.isOrderValid()}
          goNext={this.goNext}
          skip={this.skipStep2}
        />
      </OrderContainer>
    );
  }
}

ClothesContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  fastVariantRoute: PropTypes.string.isRequired,
  estimationVariantRoute: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  clothes: state.order.clothes,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClothesContainer);
